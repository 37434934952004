import {SectionWrapper} from "../../section-wrapper/sectionWrapper.styles";
import InfoItem from "../info-item/info-item.component";

import "./account-info.styles.scss";
import FileUpload from "../../../pages/user/branding-file-upload";

const AccountInfoAdvisor = ({info}) => (
    <>
    <SectionWrapper title={"Account Information"}>
        <div className="container-fluid py-4">
            <div>
                <div className="card h-100 shadow-sm">
                    <div className="card-header bg-primary text-white">
                        <h5 className="card-title mb-0">Advisor Details</h5>
                    </div>
                    <ul className="list-group list-group-flush">
                        <InfoItem
                            label="Name"
                            value={`${info.firstName} ${info.lastName}`}
                        />
                        <InfoItem label="Advisor Id" value={info.advisorId}/>
                        <InfoItem label="Email" value={info.email}/>
                        <InfoItem label="Relationship" value={info.relationship}/>
                        <InfoItem label="Description" value={info.description}/>
                    </ul>
                </div>
            </div>
        </div>
    </SectionWrapper>
    <SectionWrapper title="Logo & Theme Selector">
        <FileUpload currentUser={info}/>
    </SectionWrapper>
    </>
);

export default AccountInfoAdvisor;
