import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {selectCurrentUser} from "../../redux/user/user.selectors";
import {Modal} from "react-bootstrap";
import toastr from "toastr";
import {groupInsuranceCoverageData} from "../../pages/reports-pages/quotes-dashboard"
import PDFGenerator from "./quote-pdf.component";
import {QuoteEstimatePerType} from "./quote-estimate-per-type";

export const accountTypeHeaders = {
    HSA: "Health Spending Account",
    LSA: "Lifestyle Spending Account",
    CoreHealth: "CoreHealth+ Account",
    Flex: "Flexible Spending Account"
}

const QuoteTool = ({ currentUser, showQuoteTool, setShowQuoteTool, customPercentage, handleUpdatePercentage }) => {
    const apiUrl = process.env.NODE_ENV === 'production' ? 
        process.env.REACT_APP_API_URL_PROD : process.env.NODE_ENV === 'development' ? 
            process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LOCAL;

    const [formData, setFormData] = useState({
        userId: currentUser.id,
        advisorCompany: "",
        advisorName: "",
        advisorEmail: "",
        clientCompany: "",
        clientName: "",
        clientEmail: "",
        adminFee: "",
        province: "",
        companyType: "Incorporated",
        planAccount: "",
    });
    const [selectedPlans, setSelectedPlans] = useState([]);
    const [planAccounts, setPlanAccounts] = useState([]);
    const [proposal, setProposal] = useState({});
    const [userLogo, setUserLogo] = useState("/logo.png");
    const [userColor, setUserColor] = useState('');

    const updatePlanAccount = (index, updatedAccount) => {
        setPlanAccounts((prev) =>
            prev.map((account, i) => (i === index ? updatedAccount : account))
        );
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setSelectedPlans((prev) => {
            if (checked) {
                return [...prev, value];
            } else {
                return prev.filter((item) => item !== value);
            }
        });

        setPlanAccounts((prev) => {
            if (checked) {
                const accountExists = prev.some((plan) => plan.accountType === value);

                if (!accountExists) {
                    if (value === "Flex" && prev.some((plan) => plan.accountType === "HSA" || plan.accountType === "LSA")) {
                        prev = prev.filter((plan) => plan.accountType !== "HSA" && plan.accountType !== "LSA");
                        setSelectedPlans(prev => prev.filter(item => item !== "HSA" && item !== "LSA"));
                    }
                    if (value === "CoreHealth" && prev.some((plan) => plan.accountType === "HSA")) {
                        prev = prev.filter((plan) => plan.accountType !== "HSA");
                        setSelectedPlans(prev => prev.filter(item => item !== "HSA"));
                    }
                    
                    return [
                        ...prev,
                        {
                            "accountType": value,
                            "accountTotalBudget": 0,
                            "benefitCategories": [{
                                "description": "",
                                "bencatBudget": "",
                                "limits": {
                                    "single": {
                                        "noOfEmployees": "",
                                        "limitPerEmployee": ""
                                    },
                                    "couple": {
                                        "noOfEmployees": "",
                                        "limitPerEmployee": ""
                                    },
                                    "family": {
                                        "noOfEmployees": "",
                                        "limitPerEmployee": ""
                                    }
                                }
                            }]
                        }
                    ];
                } else {
                    return prev;
                }
                
            } else {
                return prev.filter((plan) => plan.accountType !== value);
            }
        });
    };
    
    useEffect(() => {
        setFormData({
            ...formData,
            "planAccount": JSON.stringify(planAccounts),
        });
    }, [planAccounts]);
    
    useEffect(() => {
        setProposal(formData);
    }, [formData]);

    useEffect(async () => {
        await getUserLogo();
    }, []);

    const getUserLogo = async () => {
        try {
            const accessToken = currentUser.token;
            const response = await fetch(`${apiUrl}/proposals/getuserdetails/${currentUser.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                if (data.advisorDetails) {
                    setUserLogo(data.advisorDetails.advisorLogo);
                    setUserColor(data.advisorDetails.advisorColor);
                }
            }
            else {
                console.error("HTTP Error:", response.status, response.statusText);
                return;
            }
        } catch (err) {
            toastr.error("Failed to retrieve advisor logo");
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        const accessToken = currentUser.token;
        try {
            const response = await fetch(`${apiUrl}/proposals/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok) {
                toastr.success(data.message);
                
                setFormData({
                    user_Id: currentUser.id,
                    advisorCompany: "",
                    advisorName: "",
                    advisorEmail: "",
                    clientCompany: "",
                    clientName: "",
                    clientEmail: "",
                    adminFee: "",
                    province: "",
                    companyType: "Incorporated"
                });
                setPlanAccounts([]);
                setSelectedPlans([]);
                
                window.location.reload();
            } else {
                toastr.error(`Error: ${data.message}`);
            }
            setShowQuoteTool(false);
        } catch (error) {
            toastr.error(`Error: ${error.message}`);
        } 
    };

    const handleIncorporatedFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Modal className="quote-modal" show={showQuoteTool} onHide={() => setShowQuoteTool(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Generate Quote</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "900px", overflowY: "auto" }}>
                <div>
                    <form id="quoteToolForm" onSubmit={handleSubmit}>
                    <div className="row p-2">
                        <div className="card shadow p-2">
    
                            <div className="card-header bg-primary text-white">
                                <h5 className="mb-0">Advisor Information</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="advisorName" className="form-label"
                                               style={{fontWeight: "bold"}}>
                                            Name 
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="advisorName"
                                            name="advisorName"
                                            value={formData.advisorName}
                                            onChange={handleIncorporatedFormDataChange}
                                            className="form-control"
                                            placeholder="Enter the advisor's name"
                                            required
                                        />
                                    </div>
    
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="advisorCompany" className="form-label"
                                               style={{fontWeight: "bold"}}>
                                            Company 
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="advisorCompany"
                                            name="advisorCompany"
                                            value={formData.advisorCompany}
                                            onChange={handleIncorporatedFormDataChange}
                                            className="form-control"
                                            placeholder="Enter the advisor's company"
                                            required
                                        />
                                    </div>
    
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="advisorEmail" className="form-label"
                                               style={{fontWeight: "bold"}}>
                                            Email
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="advisorEmail"
                                            name="advisorEmail"
                                            value={formData.advisorEmail}
                                            onChange={handleIncorporatedFormDataChange}
                                            className="form-control"
                                            placeholder="Enter the advisor's email"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
    
                            <div className="card-header bg-primary text-white">
                                <h5 className="mb-0">Client Information</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="clientName" className="form-label" style={{fontWeight: "bold"}}>
                                            Name
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="clientName"
                                            name="clientName"
                                            value={formData.clientName}
                                            onChange={handleIncorporatedFormDataChange}
                                            className="form-control"
                                            placeholder="Enter the client's name"
                                            required
                                        />
                                    </div>
    
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="clientCompany" className="form-label"
                                               style={{fontWeight: "bold"}}>
                                            Company
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="clientCompany"
                                            name="clientCompany"
                                            value={formData.clientCompany}
                                            onChange={handleIncorporatedFormDataChange}
                                            className="form-control"
                                            placeholder="Enter the client's company"
                                            required
                                        />
                                    </div>
    
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="clientEmail" className="form-label"
                                               style={{fontWeight: "bold"}}>
                                            Email
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            id="clientEmail"
                                            name="clientEmail"
                                            value={formData.clientEmail}
                                            onChange={handleIncorporatedFormDataChange}
                                            className="form-control"
                                            placeholder="Enter the client's email"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
    
                            <div className="card-header bg-primary text-white">
                                <h5 className="mb-0">Company Information</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="adminFee" className="form-label" style={{fontWeight: "bold"}}>
                                            Admin Fee (%)
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <select id="adminFee"
                                                name="adminFee"
                                                value={formData.adminFee}
                                                onChange={handleIncorporatedFormDataChange}
                                                className="form-control"
                                                required>
                                            <option value="" disabled>Choose admin fee</option>
                                            <option value="5">5.0%</option>
                                            <option value="5.5">5.5%</option>
                                            <option value="6">6.0%</option>
                                            <option value="6.5">6.5%</option>
                                            <option value="7">7.0%</option>
                                            <option value="7.5">7.5%</option>
                                            <option value="8">8.0%</option>
                                            <option value="8.5">8.5%</option>
                                            <option value="9">9.0%</option>
                                            <option value="9.5">9.5%</option>
                                            <option value="10">10.0%</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="province" className="form-label" style={{fontWeight: "bold"}}>
                                            Province
                                            <span style={{color: "red", fontSize: "16px", marginLeft: "2px"}}>*</span>
                                        </label>
                                        <select
                                            id="province"
                                            name="province"
                                            value={formData.province}
                                            onChange={handleIncorporatedFormDataChange}
                                            className="form-control"
                                            required
                                        >
                                            <option value="" disabled>
                                                Select a province
                                            </option>
                                            <option value="AB">Alberta</option>
                                            <option value="BC">British Columbia</option>
                                            <option value="MB">Manitoba</option>
                                            <option value="NB">New Brunswick</option>
                                            <option value="NL">Newfoundland and Labrador</option>
                                            <option value="NT">Northwest Territories</option>
                                            <option value="NS">Nova Scotia</option>
                                            <option value="NU">Nunavut</option>
                                            <option value="ON">Ontario</option>
                                            <option value="PE">Prince Edward Island</option>
                                            <option value="QC">Quebec</option>
                                            <option value="SK">Saskatchewan</option>
                                            <option value="YT">Yukon</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="p-2 border rounded bg-light">
                                    <h6>Choose a Plan</h6>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                id="HSA"
                                                value="HSA"
                                                onChange={handleCheckboxChange}
                                                checked={selectedPlans.includes("HSA")}
                                                disabled={selectedPlans.includes("CoreHealth") || selectedPlans.includes("Flex")}
                                            />
                                            <label className="form-check-label" htmlFor="HSA">
                                                Health Spending Account (HSA)
                                            </label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                id="LSA"
                                                value="LSA"
                                                onChange={handleCheckboxChange}
                                                checked={selectedPlans.includes("LSA")}
                                                disabled={selectedPlans.includes("Flex")}
                                            />
                                            <label className="form-check-label" htmlFor="LSA">
                                                Lifestyle Spending Account (LSA)
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                id="CoreHealth"
                                                value="CoreHealth"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label" htmlFor="CoreHealth">
                                                CoreHealth+ Account
                                            </label>
                                        </div>
                                        <div className="col-sm-6">
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                id="Flex"
                                                value="Flex"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label className="form-check-label" htmlFor="Flex">
                                                Flexible Spending Account (HSA & LSA)
                                            </label>
                                        </div>
                                    </div>

                                    {planAccounts.length > 0 &&
                                        planAccounts.map((account, index) => (
                                            <PlanEstimates key={index}
                                                           account={account} currentUser={currentUser}
                                                           onUpdate={(updatedAccount) => updatePlanAccount(index, updatedAccount)}
                                                           adminFee={formData.adminFee} province={formData.province}
                                                           handleUpdatePercentage={handleUpdatePercentage}
                                                           groupInsuranceCoverageData={groupInsuranceCoverageData}
                                            />
                                        ))
                                    }
                                </div>
                                <div className="mt-3" style={{display: "flex", justifyContent: "center"}}>
                                    <PDFGenerator proposal={proposal} groupInsuranceCoverageData={groupInsuranceCoverageData}
                                                  userLogo={userLogo} userColor={userColor} customPercentage={customPercentage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    );
};


const PlanEstimates = ({ account, onUpdate, adminFee, province, handleUpdatePercentage, groupInsuranceCoverageData}) => {
    const [benefitCategories, setBenefitCategories] = useState(account.benefitCategories);

    const addNewCategory = () => {
        const newCategory = {
            description: "",
            bencatBudget: "",
            limits: {
                single: {noOfEmployees: "", limitPerEmployee: ""},
                couple: {noOfEmployees: "", limitPerEmployee: ""},
                family: {noOfEmployees: "", limitPerEmployee: ""},
            },
        };
        const updatedCategories = [...benefitCategories, newCategory];
        setBenefitCategories(updatedCategories);
        onUpdate({...account, benefitCategories: updatedCategories});
    };

    const removeCategory = (index) => {
        const updatedCategories = benefitCategories.filter((_, i) => i !== index);
        setBenefitCategories(updatedCategories);
        onUpdate({...account, benefitCategories: updatedCategories});
    };

    const handleCategoryChange = (index, key, value) => {
        const updatedCategories = benefitCategories.map((cat, i) => {
            if (i === index) {
                return {
                    ...cat,
                    [key]: value
                }
            } else {
                return cat;
            }
        });
        setBenefitCategories(updatedCategories);
        onUpdate({...account, benefitCategories: updatedCategories});
    };

    const handleBenefitCategoryLimitChange = (e, coverageLevel, index) => {
        const {id, value} = e.target;

        const updatedCategories = benefitCategories.map((cat, i) => {
            if (i === index) {
                const updatedLimits = {
                    ...cat.limits,
                    [coverageLevel]: {
                        ...cat.limits[coverageLevel],
                        [id]: value,
                    },
                };

                const newBencatBudget = Object.keys(updatedLimits).reduce((total, level) => {
                    const {noOfEmployees, limitPerEmployee} = updatedLimits[level];
                    const employees = parseFloat(noOfEmployees) || 0;
                    const limit = parseFloat(limitPerEmployee) || 0;
                    
                    const fee = parseFloat(adminFee) || 0;
                    const feeTotal = employees * limit * (fee/100);
                        
                    return (total + employees * limit) + feeTotal;
                }, 0);

                return {
                    ...cat,
                    limits: updatedLimits,
                    bencatBudget: newBencatBudget.toFixed(2),
                };
            } else {
                return cat;
            }
        });

        setBenefitCategories(updatedCategories);

        onUpdate({...account, benefitCategories: updatedCategories});
    };
    
    return (
        <div>
            <hr/>
            <h3>{accountTypeHeaders[account.accountType]}</h3>
            {benefitCategories.map((category, index) => (
                <div key={index} className="mb-4">
                    <p>Calculate by Employee Class:</p>
                    <table className="mt-2 p-3 table">
                        <thead>
                        <tr>
                            <th>Job Description</th>
                            <th>Number of Employees</th>
                            <th>Limit/Employee</th>
                            <th>Budget (excludes taxes)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                <select
                                    className="form-select"
                                    value={category.description}
                                    onChange={(e) => handleCategoryChange(index, "description", e.target.value)}
                                >
                                    <option>Select</option>
                                    <option value="Executive">Executive</option>
                                    <option value="Management">Management</option>
                                    <option value="Employee Full-time">Employee Full-time</option>
                                    <option value="Employee Part-time">Employee Part-time</option>
                                </select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Single</th>
                            <td>
                                <input className="form-control"
                                       id="noOfEmployees"
                                       value={benefitCategories[index]?.limits?.single?.noOfEmployees || ""}
                                       onChange={(e) => handleBenefitCategoryLimitChange(e, "single", index)}
                                />
                            </td>
                            <td>
                                <input className="form-control"
                                       id="limitPerEmployee"
                                       value={benefitCategories[index]?.limits?.single?.limitPerEmployee || ""}
                                       onChange={(e) => handleBenefitCategoryLimitChange(e, "single", index)}
                                />
                            </td>
                            <td rowSpan="3">
                                <input
                                    className="form-control"
                                    value={category.bencatBudget}
                                    onChange={(e) => handleCategoryChange(index, "bencatBudget", Number(e.target.value) || 0)}
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Couple</th>
                            <td>
                                <input className="form-control"
                                       id="noOfEmployees"
                                       value={benefitCategories[index]?.limits?.couple?.noOfEmployees || ""}
                                       onChange={(e) => handleBenefitCategoryLimitChange(e, "couple", index)}
                                />
                            </td>
                            <td>
                                <input className="form-control"
                                       id="limitPerEmployee"
                                       value={benefitCategories[index]?.limits?.couple?.limitPerEmployee || ""}
                                       onChange={(e) => handleBenefitCategoryLimitChange(e, "couple", index)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Family</th>
                            <td>
                                <input className="form-control"
                                       id="noOfEmployees"
                                       value={benefitCategories[index]?.limits?.family?.noOfEmployees || ""}
                                       onChange={(e) => handleBenefitCategoryLimitChange(e, "family", index)}
                                />
                            </td>
                            <td>
                                <input className="form-control"
                                       id="limitPerEmployee"
                                       value={benefitCategories[index]?.limits?.family?.limitPerEmployee || ""}
                                       onChange={(e) => handleBenefitCategoryLimitChange(e, "family", index)}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <button
                        type="button"
                        className="btn btn-danger mt-2"
                        onClick={() => removeCategory(index)}
                    >
                        Remove Category
                    </button>
                </div>
            ))}
            <button type="button" className="mb-3 btn btn-primary" onClick={addNewCategory}>
                Add New Category
            </button>
            <QuoteEstimatePerType planAccount={account} adminFee={adminFee} province={province}
                                  handleUpdatePercentage={handleUpdatePercentage}
                                  groupInsuranceCoverageData={groupInsuranceCoverageData}/>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(QuoteTool);
