import {useEffect, useState} from "react";

const percentages = [0.4, 0.65, 0.8, 1.0];

// 5% = AB 1, BC 2, MB 3, NT 11, NU 12, QC 10, SK 9, YT 13
// 13% = ON 7
// 15% = NB 4, NL 5, NS 6, PE 8
const provincesTaxRates = {
    AB: 0.05,
    BC: 0.05,
    MB: 0.05,
    NB: 0.15,
    NL: 0.15,
    NT: 0.05,
    NS: 0.15,
    NU: 0.05,
    ON: 0.13,
    PE: 0.15,
    QC: 0.05,
    SK: 0.05,
    YT: 0.05
};

const accountTypeHeaders = {
    HSA: "Health Spending Account",
    LSA: "Lifestyle Spending Account",
    CoreHealth: "CoreHealth Account",
    Flex: "Flexible Spending Account"
}

export const QuoteEstimatePerType = ({ planAccount, adminFee, province, handleUpdatePercentage, groupInsuranceCoverageData }) => {
    const [accountType, setAccountType] = useState('');
    
    const [totals, setTotals] = useState({});
    const [customPercentage, setCustomPercentage] = useState({});
    const [customCosts, setCustomCosts] = useState({ limitsTotal: 0, adminFee: 0, tax: 0 });
    const [totalInsurancePremium, setTotalInsurancePremium] = useState(0);

    useEffect(() => {
        setAccountType(planAccount.accountType);
        
        try {
            let acctTotals = {};
            const chNoOfEmployees = { single: 0, couple: 0, family: 0 };

            if (!acctTotals[planAccount.accountType]) {
                acctTotals[planAccount.accountType] = { single: 0, couple: 0, family: 0 };
            }
            
            planAccount.benefitCategories.forEach((category) => {
                const {single, couple, family} = category.limits;

                acctTotals[planAccount.accountType].single +=
                    parseFloat(single.limitPerEmployee) *
                    parseInt(single.noOfEmployees, 10) || 0;

                acctTotals[planAccount.accountType].couple +=
                    parseFloat(couple.limitPerEmployee) *
                    parseInt(couple.noOfEmployees, 10) || 0;

                acctTotals[planAccount.accountType].family +=
                    parseFloat(family.limitPerEmployee) *
                    parseInt(family.noOfEmployees, 10) || 0;

                if (planAccount.accountType === "CoreHealth") {
                    chNoOfEmployees["single"] += parseInt(single.noOfEmployees, 10);
                    chNoOfEmployees["couple"] += parseInt(couple.noOfEmployees, 10);
                    chNoOfEmployees["family"] += parseInt(family.noOfEmployees, 10);
                }
            });
            setTotals(acctTotals);

            calculateInsurancePremiums(chNoOfEmployees);
            
            // Calculate custom costs
            setCustomCosts(calculateCustomCost(totals[planAccount.accountType], customPercentage[planAccount.accountType]));
        } catch (error) {
            console.error("Error parsing or calculating totals:", error);
        }
    }, [planAccount, adminFee, province]);

    const calculateCost = (totals, percent) => ({
        single: totals.single * percent || 0,
        couple: totals.couple * percent || 0,
        family: totals.family * percent || 0,
    });

    const calculateAdminFee = (totals, percent) => {
        return (
            (totals.single * percent + totals.couple * percent + totals.family * percent) * adminFee / 100
        ) || 0;
    };

    const calculateTax = (adminFeeTotal) => {
        return adminFeeTotal * provincesTaxRates[province] || 0;
    };

    const calculateAdditionalONTax = (limits, percent) => {
        const limitsTotal =
            limits.single * percent +
            limits.couple * percent +
            limits.family * percent;
        const adminFee = calculateAdminFee(limits, percent);
        return limitsTotal * 0.08 + (limitsTotal + adminFee) * 0.02;
    }

    const calculateInsurancePremiums = (chNoOfEmployees) => {
        const singleRate = 29.99; // the monthly insurance premium for single
        const coupleRate = 39.99; // the monthly insurance premium for couples
        const familyRate = 39.99; // the monthly insurance premium for family
        const hsaCost = 0.1; // the percentage of HSA spend that it costs the client

        if (province === 'ON') {
            setTotalInsurancePremium((chNoOfEmployees.single || 0) * singleRate * 12 * 1.08 +
                (chNoOfEmployees.couple || 0) * coupleRate * 12 * 1.08 +
                (chNoOfEmployees.family || 0) * familyRate * 12 * 1.08
            );
        }
        else {
            setTotalInsurancePremium((chNoOfEmployees.single || 0) * singleRate * 12 +
                (chNoOfEmployees.couple || 0) * coupleRate * 12 +
                (chNoOfEmployees.family || 0) * familyRate * 12
            );
        }
    }
    
    const calculateTotalCost = (limits, percent, accountType) => {
        const limitsTotal =
            limits.single * percent +
            limits.couple * percent +
            limits.family * percent;
        const adminFee = calculateAdminFee(limits, percent);
        const tax = calculateTax(adminFee);

        const total = limitsTotal + adminFee + tax;
        return accountType !== "CoreHealth" ? total : total + parseFloat(totalInsurancePremium);
    };

    const calculateTotalCostWithAdditionalTax = (limits, percent, accountType) => {
        const limitsTotal =
            limits.single * percent +
            limits.couple * percent +
            limits.family * percent;
        const adminFee = calculateAdminFee(limits, percent);
        const tax = calculateTax(adminFee);
        const addtlTax = calculateAdditionalONTax(limits, percent);

        if (accountType === "CoreHealth") {
            return limitsTotal + adminFee + tax + addtlTax + parseFloat(totalInsurancePremium);
        }
        return limitsTotal + adminFee + tax + addtlTax;
    };

    // TODO: Call all calculation using useEffect
    
    // Custom Percentage Calculation
    const handleCustomPercentageChange = (accountType, value) => {
        handleUpdatePercentage(accountType, value);
        setCustomPercentage((prev) => ({
            ...prev,
            [accountType]: parseFloat(value) || 0,
        }));
        
        // Calculate custom costs
        setCustomCosts(calculateCustomCost(totals[accountType], value));
    };

    const calculateCustomCost = (limits, customPercent) => {
        const percent = customPercent / 100;
        if (limits) {
            return {
                limitsTotal:
                    (limits.single * percent +
                        limits.couple * percent +
                        limits.family * percent) || 0,
                adminFee: calculateAdminFee(limits, percent) || 0,
                tax: calculateTax(calculateAdminFee(limits, percent)) || 0,
            }
        } else {
            return { limitsTotal: 0, adminFee: 0, tax: 0}
        }
    };

    const calculateAdditionalONTaxCustomCost = (customLimits) => {
        return customLimits.limitsTotal * 0.08 + (customLimits.limitsTotal + customLimits.adminFee) * 0.02;
    }
    
    const calculateTotalCustomCost = (customCosts, accountType) => {
        let total = customCosts.limitsTotal + customCosts.adminFee + customCosts.tax;

        if (province === "ON" && accountType !== "LSA") {
            total += calculateAdditionalONTaxCustomCost(customCosts)
        }
        if (accountType === "CoreHealth") {
            total += parseFloat(totalInsurancePremium)
        }
        return total;
    }
    
    return (
        <div>
            {totals[accountType] &&
            <div className="shadow-lg p-3 bg-white">
                <h3>{accountTypeHeaders[accountType]}</h3>
                <table className="table table-hover">
                    <thead>
                        <tr align="center">
                            <th></th>
                            <th>Typical Usage</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Custom % Usage</th>
                        </tr>
                    </thead>
                    <tbody style={{textAlign: "center"}}>
                        <tr>
                            <th>% {accountType === "CoreHealth" ? "HSA" : accountType} Used</th>
                            {percentages.map((percent, idx) => (
                                <th key={idx}>{(percent * 100)}%</th>
                            ))}
                            <td align="center" width="15%">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={customPercentage[accountType]}
                                    onChange={(e) =>
                                        handleCustomPercentageChange(accountType, e.target.value)
                                    }
                                />
                            </td>
                        </tr>
                        {["Single", "Couple", "Family"].map((category) => (
                            <tr key={category}>
                                <th>{`${accountType} $ Spend (${category})`}</th>
                                {percentages.map((percent, idx) => (
                                    <td key={idx} style={{textAlign: "right"}}>
                                        ${calculateCost(totals[accountType], percent)[category.toLowerCase()].toFixed(2)}
                                    </td>
                                ))}
                                <td style={{textAlign: "right"}}>
                                    ${(totals[accountType][category.toLowerCase()] * customPercentage[accountType] / 100 || 0).toFixed(2)}
                                </td>
                            </tr>
                        ))}
                        {accountType === "CoreHealth" && (
                            <tr>
                                <th>Total Core Insurance Premium{province === "ON" && "*"}</th>
                                <td style={{textAlign: "right"}}>${totalInsurancePremium.toFixed(2)}</td>
                                <td style={{textAlign: "right"}}>${totalInsurancePremium.toFixed(2)}</td>
                                <td style={{textAlign: "right"}}>${totalInsurancePremium.toFixed(2)}</td>
                                <td style={{textAlign: "right"}}>${totalInsurancePremium.toFixed(2)}</td>
                                <td style={{textAlign: "right"}}>${totalInsurancePremium.toFixed(2)}</td>
                            </tr>
                        )}
                        <tr>
                            <th>{accountType === "CoreHealth" ? "HSA" : accountType} Admin Fee</th>
                            {percentages.map((percent, idx) => (
                                <td key={idx} style={{textAlign: "right"}}>
                                    ${calculateAdminFee(totals[accountType], percent).toFixed(2)}
                                </td>
                            ))}
                            <td style={{textAlign: "right"}}>${customCosts.adminFee.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>GST/HST{accountType === "CoreHealth" && province === "ON" ? "**" : "*"}</th>
                            {percentages.map((percent, idx) => (
                                <td key={idx} style={{textAlign: "right"}}>
                                    ${calculateTax(calculateAdminFee(totals[accountType], percent)).toFixed(2)}
                                </td>
                            ))}
                            <td style={{textAlign: "right"}}>${customCosts.tax.toFixed(2)}</td>
                        </tr>
                        {province === "ON" && accountType !== "LSA" ?
                            <>
                                <tr>
                                    <th>Additional ON Tax{accountType === "CoreHealth" ? "***": "**"}</th>
                                    {percentages.map((percent, idx) => (
                                        <td key={idx} style={{textAlign: "right"}}>
                                            ${calculateAdditionalONTax(totals[accountType], percent).toFixed(2)}
                                        </td>
                                    ))}
                                    <td style={{textAlign: "right"}}>
                                        ${calculateAdditionalONTaxCustomCost(customCosts, customPercentage).toFixed(2)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Total Cost (Annual Limit)</th>
                                    {percentages.map((percent, idx) => (
                                        <td key={idx} style={{textAlign: "right"}}>
                                            <strong>
                                                ${calculateTotalCostWithAdditionalTax(totals[accountType], percent, accountType).toFixed(2)}
                                            </strong>
                                        </td>
                                    ))}
                                    <td style={{textAlign: "right"}}>
                                        <strong>
                                            ${calculateTotalCustomCost(customCosts, accountType).toFixed(2)}
                                        </strong>
                                    </td>
                                </tr>
                            </>
                            :
                            <tr>
                                <th>Total Cost (Annual Limit)</th>
                                {percentages.map((percent, idx) => (
                                    <td key={idx} style={{textAlign: "right"}}>
                                        ${(calculateTotalCost(totals[accountType], percent, accountType)).toFixed(2)}
                                    </td>
                                ))}
                                <td style={{textAlign: "right"}}>
                                    ${(calculateTotalCustomCost(customCosts, accountType)).toFixed(2)}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>

                {province === "ON" ?
                    accountType === "CoreHealth" ?
                        <p style={{fontSize: "12px"}}>
                            * For Ontario, CoreHealth Insurance Premium includes an 8% RST.<br/>
                            ** {provincesTaxRates[province] * 100 || 5}% provincial GST/HST rate.<br/>
                            *** Additional ON tax includes 8% RST & 2% premium tax on the claim amount, 13% HST & 2% premium
                            tax on the admin fee.<br/><br/>
                            Note: Calculation includes GST/HST for total dollars spent regardless of HSA or LSA allocation.
                            Provincial taxes not applicable on any LSA reimbursements when the plan goes live. LSA claim
                            amount is exempt from RST and will be reimbursed.
                        </p> :
                        <p style={{fontSize: "12px"}}>
                            * {provincesTaxRates[province] * 100 || 5}% provincial GST/HST rate.<br/>
                            ** Additional ON tax includes 8% RST & 2% premium tax on the claim amount, 13% HST & 2% premium
                            tax on the admin fee.<br/><br/>
                            Note: Calculation includes GST/HST for total dollars spent regardless of HSA or LSA allocation.
                            Provincial taxes not applicable on any LSA reimbursements when the plan goes live. LSA claim
                            amount is exempt from RST and will be reimbursed.
                        </p> :
                    <p>* {provincesTaxRates[province] * 100 || 5}% provincial GST/HST rate.</p>
                }
                {accountType === "CoreHealth" &&
                    <GroupInsuranceCoverage groupInsuranceCoverageData={groupInsuranceCoverageData}/>}
            </div>
            }
        </div>
    )
}

const GroupInsuranceCoverage = ({groupInsuranceCoverageData}) => {
    return (
        <div className="mt-3">
            <h4>Group Insurance Coverage</h4>
            <table className="table table-hover">
                <thead>
                <tr>
                    <th>Coverage</th>
                    <th>Single</th>
                    <th>Family</th>
                </tr>
                </thead>
                <tbody>
                {groupInsuranceCoverageData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.coverage}</td>
                        <td>{item.single}</td>
                        <td>
                            {item.family.split("\n").map((line, i) => (
                                <div key={i}>{line}</div>
                            ))}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}