import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import ClientsCard from "../../components/clients-card/clients-card.component";

import DashboardWrapper from "../../components/dashboard-wrapper/dashboard-wrapper.component";
import DistroList from "../../components/distros-list/distros-list.component";
import {formatDate} from "../../components/financials/utils";

import PageWrapper from "../../components/page-wrapper/page-wrapper.components";
import {SectionWrapper} from "../../components/section-wrapper/sectionWrapper.styles";

import {ownerSidebarData} from "../../components/sidebar/sidebarData";
import {Spinner} from "../../components/spinner/spinner.component";
import {setCurrentDistro} from "../../redux/distro/distro.actions";
import {selectCurrentDistro} from "../../redux/distro/distro.selector";

import {selectCurrentUser} from "../../redux/user/user.selectors";

const DashboardPage = ({currentUser, currentDistro, setCurrentDistro}) => {
    const [userInfo, setUserInfo] = useState({});
    const [financialInfo, setFinancialInfo] = useState({
        commission: [],
        summary: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_URL_PROD
        : process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_API_URL_DEV
            : process.env.REACT_APP_API_URL_LOCAL;

    const fetchData = async (distroId) => {
        try {
            setIsLoading(true);
            const accessToken = currentUser.token;
            const response = await fetch(
                `${apiUrl}/users/${currentUser.id}/${distroId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (response.ok) {
                const data = await response.json();
                setUserInfo(data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                setLoadingError(false);
                throw new Error(response.statusText);
            }
        } catch (err) {
            setLoadingError(true);
        }
    };

    const fetchFinancialData = async (startingDate, endingDate) => {
        try {
            setIsLoading(true);
            const accessToken = currentUser.token;
            const response = await fetch(
                `${apiUrl}/financials/${currentDistro}/${formatDate(
                    startingDate
                )}/${formatDate(endingDate)}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setIsLoading(false);
                if (isLoading === false) {
                    setFinancialInfo(data);
                }
            } else {
                setIsLoading(false);
                throw new Error(response.statusText);
            }
        } catch (err) {
            localStorage.clear();
            alert(
                "An error has occured while retrieving data. Please refresh the page and try again."
            );
        }
    };

    useEffect(() => {
        if (currentUser.distros.length === 1) {
            setCurrentDistro(currentUser.distros[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentDistro !== null) {
            fetchData(currentDistro);
            let defaultEndDate = new Date();
            defaultEndDate.setDate(1);
            defaultEndDate.setHours(-1);
            let defaultStarDate = new Date(defaultEndDate.getFullYear(), defaultEndDate.getMonth(), 1);
            if (currentDistro !== null) {
                fetchFinancialData(defaultStarDate, defaultEndDate);
            }
            return (isLoading) => (isLoading = true);
        } else {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDistro]);

    const handleClick = (e) => {
        setCurrentDistro(e.target.dataset.id);
    };

    const amount =
        financialInfo.commission.length > 0
            ? financialInfo.commission.reduce((a, b) => ({
                amount: Number(a.amount) + Number(b.amount),
            }))
            : 0;

    const total = amount === 0 ? 0 : amount.amount;
    return (
        <>
            {currentDistro === null ? (
                <DistroList distros={currentUser.distros} handleClick={handleClick}/>
            ) : !isLoading ? (
                !loadingError ? (
                    <PageWrapper sidebarData={ownerSidebarData}>
                        <DashboardWrapper>
                            {/*<div style={{display: "flex", justifyContent: "left"}}>*/}
                            {/*    {total !== null && typeof total === 'number' && (*/}
                            {/*        <DashbaordCard*/}
                            {/*            title={"Last month's commission"}*/}
                            {/*            content={`CA  $${total.toFixed(2)} *`}*/}
                            {/*            subtitle={"(CAD) Estimate"}*/}
                            {/*            link={"View Financials"}*/}
                            {/*            path={"/user/financials"}*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*</div>*/}
                            <ClientsCard commissions={financialInfo.commission}/>
                        </DashboardWrapper>
                    </PageWrapper>
                ) : (
                    <SectionWrapper>
                        <h1 className="text-center">
                            An error has occurred while retrieving data. Please refresh the page and try again.
                        </h1>
                        <p className="text-center">
                            <a href="/" className="text-decoration-none">
                                Click here to return to the sign-in screen
                            </a>
                        </p>
                    </SectionWrapper>
                )
            ) : (
                <Spinner/>
            )}
        </>
    );
};
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    currentDistro: selectCurrentDistro,
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentDistro: (data) => dispatch(setCurrentDistro(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
