import React, {useEffect, useState} from "react";
import axios from "axios";
import { FaTrashAlt } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

const FileUpload = ({ currentUser }) => {
    const [file, setFile] = useState(null);
    const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [color, setColor] = useState(currentUser.customColor);
    const [isHovered, setIsHovered] = useState(false);

    const apiUrl =
        process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_API_URL_PROD
            : process.env.NODE_ENV === "development"
                ? process.env.REACT_APP_API_URL_DEV
                : process.env.REACT_APP_API_URL_LOCAL;

    const saveColor = async (e) => {
        try {
            const accessToken = currentUser.token;
            await fetch(`${apiUrl}/advisors/updatecolor/${currentUser.id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: `"${color}"`,
            });
        } catch (error) {
            console.error('Error saving color:', error);
        }
    };
    
    useEffect(() => {
        setUploadedImageUrl(currentUser.customLogo);
    }, [currentUser.customLogo]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        handleUpload(selectedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        handleUpload(droppedFile);
    };

    const handleUpload = async (file) => {
        if (!file) {
            alert("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axios.post(
                `${apiUrl}/advisors/upload/${currentUser.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            setUploadedImageUrl(response.data.fileUrl || null);
            setFile(null);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    const handleDelete = async () => {
        try {
            await axios.post(`${apiUrl}/advisors/delete/${currentUser.id}`);

            setUploadedImageUrl(null);
            setFile(null);
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Select a background color to enhance the logo's visibility.
        </Tooltip>
    );

    return (
        <div style={{paddingTop: "20px", display: "flex", alignItems: "center", gap: "20px"}}>
            <div>
                <OverlayTrigger
                    delay={{show: 250, hide: 400}}
                    overlay={renderTooltip}
                >
                    <FontAwesomeIcon style={{
                        position: "absolute",
                        top: "30px",
                        left: "360px",
                    }} size="lg" icon={faInfoCircle} />
                </OverlayTrigger>
            </div>
            {!uploadedImageUrl ? (
                <div
                    style={{
                        border: isDragging ? "2px dashed blue" : "2px dashed gray",
                        padding: "50px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        backgroundColor: isDragging ? "#f0f8ff" : "#fafafa",
                        width: "70%",
                        height: "200px",
                        boxSizing: "border-box",
                    }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <p style={{margin: "0", fontSize: "16px"}}>
                        Drag and drop a file here, or click to select one.
                    </p>
                    <input
                        type="file"
                        style={{display: "none"}}
                        id="file-input"
                        onChange={handleFileChange}
                    />
                    <label
                        htmlFor="file-input"
                        style={{
                            display: "inline-block",
                            marginTop: "10px",
                            padding: "5px 10px",
                            background: "blue",
                            color: "white",
                            cursor: "pointer",
                        }}
                    >
                        Select File
                    </label>
                    {file && <p style={{marginTop: "10px"}}>{file.name}</p>}
                </div>
            ) : (
                <div
                    style={{
                        width: "70%",
                        height: "200px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        border: "2px dashed gray",
                        borderRadius: "8px",
                        backgroundColor: "#fafafa",
                    }}
                >
                    <img
                        src={uploadedImageUrl}
                        alt="Uploaded Preview"
                        style={{
                            maxHeight: "150px",
                            maxWidth: "600px",
                            borderRadius: "8px",
                        }}
                    />
                    <FaTrashAlt
                        onClick={handleDelete}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer",
                            color: "red",
                        }}
                    />
                </div>
            )}
            <div style={{
                border: "2px dashed gray",
                padding: "50px",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundColor: isDragging ? "#f0f8ff" : "#fafafa",
                width: "30%",
                height: "200px",
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
            }}>
                Select a color:
                <input
                    type="color"
                    value={color}
                    onChange={e => setColor(e.target.value)}
                    style={{
                        width: "150px",
                        height: "50px",
                        cursor: "pointer"
                    }}
                />
                <button className="form-control"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{position: "absolute",
                            backgroundColor: isHovered ? "#e8dfdf" : "#ffffff",
                            top: "130px", 
                            left: "165px", 
                            width: "100px",
                            cursor: "pointer",
                            outline: "2px solid #e8dfdf"}} 
                        onClick={saveColor}>Save</button>
            </div>
        </div>
    );
};

export default FileUpload;
